import React, { Fragment } from "react";
import { Link } from "gatsby";
import EpisodeStub from "./episodeStub";


const EpisodeGroup = ({eps: episodes, title, body}) => {

  return (
    <div className="inner">
      <h2 className="major">{title}</h2>
      {body && (<p>{body}</p>)}
      <section className="features">
        {episodes.map(
          (episode, x) => {
            return <EpisodeStub ep={episode} />
          }
        )}
      </section>
      <ul className="actions">
        <li><Link to="/episodes/" className="button">Browse All</Link></li>
      </ul>
    </div>
  )
}

export default EpisodeGroup
import * as React from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby";
import Layout from "../components/layout"
import EpisodeGroup from "../components/episodesGroup";

import '../assets/css/main.css'
import '../assets/css/noscript.css'

import Logo from "../images/logo.jpg"
import Microphone from "../images/microphone.jpg"
import Manga from "../images/manga.jpg"
import Manga2 from "../images/manga2.jpg"
import ApplePodcastBadge from "../images/applePodcastBadge.svg"

const IndexPage = ({data}) => {
  const latest = data.latest

  return (
    <Layout>
      <head>
        <Helmet
          title="OverMangaCast"
        >
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          <link rel="stylesheet" href="assets/css/main.css" />
        </Helmet>
        <noscript><link rel="stylesheet" href="assets/css/noscript.css" /></noscript>
      </head>
      <body className="is-preload">
        <section id="banner">
          <div className="inner">
            <div className="logo"><span><img className="icon" src={Logo} alt="OverMangaCast Logo"/></span></div>
            <h2>OverMangaCast</h2>
            <p>That Time We Woke Up in a Podcast and had to Explain Manga: Our Heated Adventures Over-Analyzing Manga We Find Interesting</p>
          </div>
        </section>
        <section id="wrapper">
              <section id="one" className="wrapper spotlight style1">
                <div className="inner">
                  <Link to="/episodes/" className="image"><img src={Microphone} alt="Recording microphone" /></Link>
                  <div className="content">
                    <h2 className="major">Listen to Our Podcast</h2>
                    <p><iframe src="https://castbox.fm/app/castbox/player/id3787910?v=8.22.11&autoplay=0" frameBorder="0" width="100%" height="500"></iframe></p>
                    <p>
                      <h1>Subscribe: 
                      <a href="https://podcasts.apple.com/us/podcast/overmangacast/id1552224601?itsct=podcast_box_link&itscg=30200&ls=1"><i className="icon brands fab fa-itunes"></i></a>
                      <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5yZWRjaXJjbGUuY29tL2VjNDQ0MTE2LTA0OTYtNDlkNy1hMzMwLWVkY2MxZjIwMjZkMA=="><i className="icon brands fab fa-google"></i></a>
                      <a href="https://open.spotify.com/show/4ymJrtK9mImcG4MCWCf7cU"><i className="icon brands fab fa-spotify"></i></a>
                      <a href="https://www.amazon.com/OverMangaCast/dp/B08JJTWXPJ"><i className="icon brands fab fa-audible"></i></a>
                      <a href="https://www.stitcher.com/show/overmangacast"><i className="icon brands fab fa-stitcher"></i></a>
                      <a href="https://www.youtube.com/channel/UCkYO_MheihNzpnC1Ya1iiog?sub_confirmation=1"><i className="icon brands fab fa-youtube"></i></a>
                      </h1>
                    </p>
                  </div>
                </div>
              </section>

              <section id="two" className="wrapper alt spotlight style2">
                <div className="inner">
                  <Link to="/#two" className="image"><img src={Manga2} alt="" /></Link>
                  <div className="content">
                    <h2 className="major">Support Us</h2>
                    <p>Like what you heard? Want to help us out? Cool, subscribing is always helpful and lets you get our episode the second we release it. Want to do a little more? Tell a friend, or just shout our name from the rooftop. Really liked us, or just want to specifically comment on us? Leave a review to let others know how you feel!</p>
                    <a href="https://www.podchaser.com/OverMangaCast?utm_source=OverMangaCast%7C1589694&utm_medium=badge&utm_content=TRCSP1589694" target="__blank" style={{textDecoration:null}} ><img alt="Podchaser - OverMangaCast" src="https://imagegen.podchaser.com/badge/TRCSP1589694.png" style={{width:"300px", maxWidth:"100%"}} width="300" height="auto"/></a>
                    <a href="//itunes.apple.com/review?id=1552224601&type=Podcast" ><img src={ApplePodcastBadge} alt="Listen on Apple Podcasts" /></a>
                  </div>
                </div>
              </section>

              <section id="three" className="wrapper spotlight style3">
                <div className="inner">
                  <Link to="/#three" className="image"><img src={Manga} alt="" /></Link>
                  <div className="content">
                    <h2 className="major">About Us</h2>
                    <p>
                    Four weebs who just want to talk about manga, or manwha, or webcomics; we're really not that picky. 
                    Every week, Matt, Sam, Jae, and Jacob dive into an arc of some manga they want to read. Sometimes its good, sometimes its bad, but every time they're going to do a deep dive. Follow along as we explore comics new, old, and sometimes just weird.
                    </p>
                  </div>
                </div>
              </section>

              <section id="four" className="wrapper alt style1">
                <EpisodeGroup eps={latest.nodes} title="Latest Episodes" />
              </section>
          </section>
      </body>
    </Layout>
  )
}

export const query = graphql`
query GetLatestEpisodes{
  latest: allFeedOverMangaCast(limit: 4, sort: {fields: isoDate, order: DESC}) {
    nodes {
      ...episodeStub
    }
  }
}
`

export default IndexPage
